import React from "react";
import {
  SEO,
  PageLayout,
  Image,
  Content,
  OutboundLink,
  ServicesList,
  PaginatedGrid,
  componentIterator,
} from "@bluefin/components";
import { Grid, Header, Button } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
} from "../utils/utils";

export default class ServicesPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const {
      fishermanBusiness,
      fishermanBusinessWebsitePage,
      bookingUrl,
      allFishermanBusinessMenuSchedule,
      allFishermanBusinessMenuCategory,
      allFishermanBusinessMenuItem,
      allFishermanBusinessMenuModifierSet,
      allFishermanBusinessMenuModifier,
    } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout hero={false} className={"services-page"}>
          <Image
            src={getComponentFiles({
              components: fishermanBusinessWebsitePage.components,
              componentName: "HeroElement",
              numToSelect: 1,
            })}
            className={"page-background-image"}
            background={true}
          >
            <div />
          </Image>
          <Grid
            stackable={true}
            textAlign={"center"}
            className={"services-list-container"}
          >
            <Grid.Column width={7} textAlign={"left"}>
              <Header
                as={"h1"}
                textAlign={"center"}
                content={getComponentContentNodeContent({
                  components: fishermanBusinessWebsitePage.components,
                  componentId: "services_header",
                  defaultValue: "Services",
                })}
              />
              <Content textAlign={"left"}>
                <Content.Markup
                  width={16}
                  className={"right-with-ctas"}
                  isMarkdown={true}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentId: "services_description",
                  })}
                  extra={
                    <div className={"ctas-container"}>
                      <Button as={OutboundLink} to={bookingUrl.url}>
                        {bookingUrl.title}
                      </Button>
                    </div>
                  }
                />
              </Content>
              <ServicesList
                defaultAllOpen={true}
                priceDisplayType={"range"}
                catalog={{
                  schedules: allFishermanBusinessMenuSchedule.nodes,
                  categories: allFishermanBusinessMenuCategory.nodes,
                  items: allFishermanBusinessMenuItem.nodes,
                  modifierSets: allFishermanBusinessMenuModifierSet.nodes,
                  modifiers: allFishermanBusinessMenuModifier.nodes,
                }}
              />
            </Grid.Column>
          </Grid>
          <Grid
            stackable={true}
            textAlign={"center"}
            className={"services-gallery-container"}
          >
            <Grid.Column width={13} textAlign={"center"}>
              <Header
                as={"h2"}
                className={"gallery-header"}
                content={getComponentContentNodeContent({
                  components: fishermanBusinessWebsitePage.components,
                  componentId: "services_gallery_header",
                  defaultValue: "Our Work",
                })}
              />
              <Content.Markup
                width={16}
                isMarkdown={true}
                content={getComponentContentNodeContent({
                  components: fishermanBusinessWebsitePage.components,
                  componentId: "services_gallery_description",
                })}
              />
              <PaginatedGrid
                columns={4}
                rows={2}
                className={"image-gallery"}
                mobile={{ columns: 2, rows: 4, stackable: false }}
                items={componentIterator({
                  iterator: getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "ImageCollection",
                  }),
                  component: (
                    <Image background={true} className={"gallery-image-item"} />
                  ),
                  propMap: { src: "__all__" },
                })}
              />
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(pageType: { eq: "Services" }) {
      title
      components {
        fastId
        componentIdentifier
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        contentNodes {
          content
        }
      }
    }
    bookingUrl: fishermanBusinessWebsitePage(
      title: { regex: "/Book Now|Make An Appointment/" }
      pageType: { eq: "External" }
    ) {
      url
      title
    }
    allFishermanBusinessMenuSchedule {
      nodes {
        categories
      }
    }
    allFishermanBusinessMenuCategory(sort: { order: ASC, fields: order }) {
      nodes {
        items
        description
        name
        _id
        interactions {
          _id
          behaviorType
          displayType
          enabled
          url
          style
          label
        }
      }
    }
    allFishermanBusinessMenuItem(
      sort: { order: ASC, fields: order }
      filter: { visible: { eq: true } }
    ) {
      nodes {
        modifierSets
        description
        _id
        name
        image
        gatsbyImage {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        variations {
          _id
          menuItem
          name
          order
          price
        }
        annotations {
          likeCount
        }
        visible
        available
        interactions {
          _id
          behaviorType
          displayType
          enabled
          url
          style
          label
        }
      }
    }
    allFishermanBusinessMenuModifierSet(sort: { order: ASC, fields: order }) {
      nodes {
        _id
        minAllowed
        maxAllowed
        modifiers
        name
      }
    }
    allFishermanBusinessMenuModifier(sort: { order: ASC, fields: order }) {
      nodes {
        _id
        name
        price
      }
    }
  }
`;
